.SplitCol {
  height: 100%;
  width: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.SplitCol--spaced {
  padding: 0 var(--vkui--size_split_col_padding_horizontal--regular);
}

.SplitCol--fixed {
  height: 100%;
  width: 1px;
  flex-grow: 1;
  flex-shrink: 1;
}

.SplitCol__fixedInner {
  height: inherit;
  width: inherit;
  min-width: inherit;
  max-width: inherit;
  position: fixed;
  transform: translateZ(0);
  overflow: hidden;
}

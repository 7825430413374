.Chip {
  display: inline-block;
  border: var(--thin-border) solid
    var(--image_border, var(--vkui--color_image_border_alpha));
  background: var(--background_content, var(--vkui--color_background_content));
  border-radius: 6px;
  height: 32px;
  box-sizing: border-box;
}

.Chip__in {
  position: relative;
  display: flex;
  height: 100%;
  padding: 0 8px;
  align-items: center;
}

.Chip--removable .Chip__in {
  padding-right: 0;
}

.Chip__content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text_primary, var(--vkui--color_text_primary));
  display: inline-block;
}

.Chip__remove {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 6px;
  margin: -1px;
  transform: translateX(-1px);
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
  border: none;
  border-radius: 2px;
  background: transparent;
  box-sizing: border-box;
}

.Chip__before {
  margin-right: 6px;
}

.Chip__after {
  margin-left: 6px;
}

/* TODO: новая адаптивность */
.Chip--sizeY-compact {
  height: 28px;
}

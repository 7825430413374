.ChipsInput {
  max-width: 100%;
}

.ChipsInput__container {
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 3px;
  z-index: 2;
  overflow: hidden;
}

.ChipsInput__chip {
  max-width: 100%;
  margin: 2px;
}

.ChipsInput__input-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 2px;
  margin-left: 10px;
}

.ChipsInput__el {
  position: relative;
  width: 100%;
  margin-bottom: 2px;
  padding: 0;
  /* Визуальная компенсация - центрируем строчные буквы */
  font-size: 15px;
  line-height: 26px;
  color: var(--text_primary, var(--vkui--color_text_primary));
  background: transparent;
  border: none;
  box-shadow: none;
  appearance: none;
  z-index: 2;
}

.ChipsInput__el:focus {
  min-width: 64px;
}

.ChipsInput__el::-ms-clear {
  display: none;
}

.ChipsInput__el:disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.ChipsInput__el::placeholder {
  color: var(--field_text_placeholder, var(--vkui--color_text_secondary));
}

.ChipsInput__el:disabled::placeholder {
  color: var(--text_secondary, var(--vkui--color_text_secondary));
}

/**
 * sizeY COMPACT
 */

.ChipsInput--sizeY-compact .ChipsInput__container {
  padding: 1px;
}

.Headline {
  display: block;
  margin: 0;
}

.Headline--l-1 {
  font-size: var(--vkui--font_headline1--font_size--regular);
  line-height: var(--vkui--font_headline1--line_height--regular);
  font-weight: var(--vkui--font_headline1--font_weight--regular);
}

.Headline--l-2 {
  font-size: var(--vkui--font_headline2--font_size--regular);
  line-height: var(--vkui--font_headline2--line_height--regular);
  font-weight: var(--vkui--font_headline2--font_weight--regular);
}

/* TODO: новая адаптивность */
.Headline--sizeY-compact.Headline--l-1 {
  font-size: var(--vkui--font_headline1--font_size--compact);
  line-height: var(--vkui--font_headline1--line_height--compact);
}

.Headline--sizeY-compact.Headline--l-2 {
  font-size: var(--vkui--font_headline2--font_size--compact);
  line-height: var(--vkui--font_headline2--line_height--compact);
}

/* TODO удалить перед релизом 5.0.0 */
.Headline--w-regular {
  font-weight: 400;
}

/* TODO удалить перед релизом 5.0.0 */
.Headline--w-medium {
  font-weight: 500;
}

/* TODO удалить перед релизом 5.0.0 */
.Headline--w-semibold {
  font-weight: 600;
}

/**
 * ANDROID
 * TODO удалить перед релизом 5.0.0
 */
.Headline--android.Headline--w-semibold {
  font-weight: 500;
}

.Headline--w-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Headline--w-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Headline--w-3 {
  font-weight: var(--vkui--font_weight_accent3);
}

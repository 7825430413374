.Textarea {
  position: relative;
}

.Textarea__el {
  margin: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  resize: none;
  appearance: none;
  line-height: 20px;
  font-size: 16px;
  color: var(--text_primary, var(--vkui--color_text_primary));
  padding: 12px;
  max-height: 204px;
  border: none;
  z-index: 2;
  position: relative;
  background: transparent;
}

.Textarea__el[cols] {
  width: initial;
}

/* TODO. v5.0.0 Новая адаптивность */
.Textarea--sizeY-compact .Textarea__el {
  font-size: 15px;
  max-height: 196px;
}

.Textarea__el::placeholder {
  color: var(--field_text_placeholder, var(--vkui--color_text_secondary));
}

.Textarea__el:disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Textarea__el:disabled::placeholder {
  color: var(--text_secondary, var(--vkui--color_text_secondary));
}

.SplitLayout {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: var(--font-common);
}

.SplitLayout__inner {
  width: 100%;
  height: 100%;
  display: flex;
}

/* Hack with using PanelHeaderSimple */
/* Better to implement simple header-like here */
.SplitLayout__inner--header {
  position: relative;
  z-index: 11;
}

.SplitLayout--ios .SplitLayout__inner--header {
  margin-top: calc(
    -1 * (var(--panelheader_height_ios) + var(--safe-area-inset-top))
  );
}

.SplitLayout--android .SplitLayout__inner--header,
.SplitLayout--vkcom .SplitLayout__inner--header {
  margin-top: calc(
    -1 * (var(--panelheader_height) + var(--safe-area-inset-top))
  );
}

.SplitLayout__popout {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.SplitLayout__modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
}

.SplitLayout__modal:empty {
  display: none;
}

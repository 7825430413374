.Separator {
  color: var(--separator_common);
}

.Separator__in:not(.Separator__in--expanded) {
  height: 1px;
  background: currentColor;
  transform-origin: center top;
}

@media (min-resolution: 2dppx) {
  .Separator__in:not(.Separator__in--expanded) {
    transform: scaleY(0.5);
  }
}

@media (min-resolution: 3dppx) {
  .Separator__in:not(.Separator__in--expanded) {
    transform: scaleY(0.32);
  }
}

.Separator__in--expanded {
  height: 16px;
  background: transparent;
}

.Separator--padded .Separator__in {
  margin-left: var(--vkui--size_base_padding_horizontal--regular);
  margin-right: var(--vkui--size_base_padding_horizontal--regular);
}

/*
 * CMP:
 * ModalPage
 */
.ModalPage--sizeX-regular .Separator--padded {
  padding-left: 8px;
  padding-right: 8px;
}

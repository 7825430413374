.IconButton {
  appearance: none;
  background: none;
  border: none;
  box-shadow: none;
  display: block;
  color: currentColor;
  padding: 0;
  margin: 0;
  position: relative;
  height: 48px;

  /**
   * Чтобы закргуление было равно половине высоты.
   * см. https://stackoverflow.com/questions/27233295/possible-to-make-border-radius-equal-to-half-the-total-height-without-javascript
   */
  border-radius: 9999px;
}

.IconButton[disabled] {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.IconButton--ios {
  border-radius: var(--vkui--size_border_radius--regular);
}

.IconButton .Icon--16 {
  padding: 16px;
}

.IconButton .Icon--16.Icon--w-8 {
  padding: 16px 14px;
}

.IconButton .Icon--24 {
  padding: 12px;
}

.IconButton .Icon--28 {
  padding: 10px;
}

.FormItem--removable .Removable__content > .IconButton,
.FormLayoutGroup--removable .Removable__content > .IconButton,
.FormField__after .IconButton {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: inherit;
}

.FormField__after .IconButton .Icon.Icon.Icon {
  padding: 0;
}

/*
 * sizeY: COMPACT
 */
.IconButton--sizeY-compact {
  height: 44px;
}

.FormField__after .IconButton--sizeY-compact {
  width: 36px;
  height: 36px;
}

.FormItem--removable .Removable__content > .IconButton--sizeY-compact,
.FormLayoutGroup--removable .Removable__content > .IconButton--sizeY-compact {
  height: 36px;
}

.IconButton--sizeY-compact .Icon--16,
.IconButton--sizeY-compact .Icon--16.Icon--w-8 {
  padding: 14px;
}

.IconButton--sizeY-compact .Icon--24 {
  padding: 10px;
}

.IconButton--sizeY-compact .Icon--28 {
  padding: 8px;
}

.Spacing {
  color: var(--separator_common, var(--vkui--color_separator_primary));
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.Spacing--separator {
  padding-left: var(--vkui--size_base_padding_horizontal--regular);
  padding-right: var(--vkui--size_base_padding_horizontal--regular);
}

.Spacing--separator-center {
  justify-content: center;
}

.Spacing--separator-bottom {
  flex-direction: column-reverse;
}

.Spacing--separator::after {
  content: "";
  display: block;
  height: 1px;
  background: currentColor;
  transform-origin: center top;
}

@media (min-resolution: 2dppx) {
  .Spacing--separator::after {
    transform: scaleY(0.5);
  }
}

@media (min-resolution: 3dppx) {
  .Spacing--separator::after {
    transform: scaleY(0.32);
  }
}

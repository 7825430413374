.FormField {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1px;
  box-sizing: border-box;
  border-radius: var(--vkui--size_border_radius--regular);
}

.FormField > * {
  border-radius: inherit;
}

.FormField__before,
.FormField__after {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 44px;
  height: 44px;
  margin: -1px;
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
  z-index: 2;
}

.FormField__before {
  color: var(--vkui--color_icon_accent);
}

.FormField__after {
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
}

.FormField__border {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  box-sizing: border-box;
  z-index: 1;
  transform-origin: left top;
  border: var(--thin-border) solid transparent;
}

.FormField--default .FormField__border {
  border-color: var(--field_border, var(--vkui--color_field_border_alpha));
  background-color: var(
    --field_background,
    var(--vkui--color_field_background)
  );
}

.FormItem--error .FormField__border {
  background-color: var(
    --field_error_background,
    var(--vkui--color_background_negative_tint)
  );
  border-color: var(--field_error_border, var(--vkui--color_stroke_negative));
}

.FormItem--valid .FormField__border {
  border-color: var(--field_valid_border, var(--vkui--color_stroke_positive));
}

.FormField--disabled {
  opacity: var(--vkui--opacity_disable_accessibility);
  cursor: default;
  pointer-events: none;
}

.FormField--hover .FormField__border {
  background-color: var(
    --field_background,
    var(--vkui--color_field_background)
  );
}

.FormField--default.FormField--hover .FormField__border {
  border-color: var(
    --icon_tertiary_alpha,
    var(--vkui--color_field_border_alpha--hover)
  );
}

.FormField:focus-within .FormField__border,
.FormField *:focus ~ .FormField__border,
.ChipsInput--focused .FormField__border {
  border-color: var(--accent, var(--vkui--color_stroke_accent));
}

/**
 * sizeY COMPACT
 * TODO v5.0.0 поправить под новую адаптивность
 */
.FormField--sizeY-compact .FormField__before,
.FormField--sizeY-compact .FormField__after {
  min-width: 36px;
  height: 36px;
}

/**
 * CMP:
 * ModalCardBase
 */
.ModalCardBase__header + .FormField,
.ModalCardBase__subheader + .FormField {
  margin-top: 16px;
}

/**
 * CMP:
 * Select
 */
.Select--pop-down .FormField__border {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.Select--pop-up .FormField__border {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/**
 * CMP:
 * CalendarHeader
 */
.CalendarHeader__picker .FormField__after {
  min-width: 12px;
  padding-right: 8px;
}

/**
 * CMP:
 * ChipsInput
 */
.ChipsInput .FormField__after {
  z-index: 3;
}

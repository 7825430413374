.UsersStack {
  display: flex;
  align-items: center;
  color: var(--text_secondary, var(--vkui--color_text_secondary));
}

.UsersStack__photos {
  display: flex;
}

.UsersStack__fill {
  fill: var(
    --placeholder_icon_background,
    var(--vkui--color_image_placeholder_alpha)
  );
}

.UsersStack__photo--others {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--vkui--color_icon_contrast_secondary);
  background: var(
    --content_placeholder_icon,
    var(--vkui--color_icon_secondary)
  );
  border-radius: 50%;
}

.UsersStack--size-xs .UsersStack__photo {
  width: 16px;
  height: 16px;
}

.UsersStack--size-s .UsersStack__photo {
  width: 24px;
  height: 24px;
}

.UsersStack--size-m .UsersStack__photo {
  width: 32px;
  height: 32px;
}

.UsersStack__photo + .UsersStack__photo {
  margin-left: -2px;
}

.UsersStack__text {
  min-width: 0;
  flex: 1;
  margin: 0 0 0 8px;
}

/* Vertical layout */

.UsersStack--l-vertical {
  flex-direction: column;
  justify-content: center;
}

.UsersStack--l-vertical .UsersStack__text {
  margin: 8px 0 0;
  text-align: center;
}

/**
 * CMP:
 * ModalCardBase
 */
.ModalCardBase .UsersStack {
  margin-top: 20px;
}

/**
 * CMP:
 * RichCell
 */
.RichCell__bottom .UsersStack {
  padding: 5px 0 0;
}
